export const INTEGER = 'integer'
export const FLOAT = 'float'
export const isNotUndefined = (value) => typeof value !== 'undefined'
export const isArrayWithLength = (value) => isArray(value) && value.length > 0
export const getArrayLength = (value = []) =>
  isArrayWithLength(value) ? value.length : 0
export const isNotUndefinedArrayWithLength = (value) =>
  isNotUndefined(value) && isArray(value) && value.length > 0
export const isArray = (value) => isNotUndefined(value) && Array.isArray(value)
export const isNumber = (value) => typeof value === 'number'
export const isNull = (value) => value === null
export const isNullableNumber = (value) => isNull(value) || isNumber(value)
export const isObject = (value) => typeof value === 'object'
export const issueTypes = {
  // customer
  LOGIN_UNSUCCESSFUL: 'login_unsuccessful',
  PRODUCT_MARKED_AS_BO: 'product_marked_as_bo',
  ORDER_CANCELED: 'order_canceled',
  PRODUCT_CANCELED: 'product_canceled',

  // admin
  LOGIN_UPDATED: 'login_updated',
  PRODUCT_BO_CANCELED_CONSUMER: 'product_bo_canceled_consumer',
  INVOICE_DETAILS_NOT_MATCH: 'invoice_details_not_match',
}
