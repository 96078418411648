//
//
//
//
//
//
//
//

export default {
  name: 'IconDashboardPath',
}
