//
//
//
//
//
//
//
//
//

import bg from '../img/bg.svg'

export default {
  components: {},
  mixins: [],
  data: () => ({
    bg,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
