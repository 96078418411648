import Vuex from 'vuex'
import ui from './modules/ui'
import socket from './modules/socket'
import user from './modules/user'

const modules = {
  ui,
  user,
  socket,
}

const store = new Vuex.Store({
  namespaced: true,
  modules,
  strict: process.env.NODE_ENV !== 'production',
})

const createStore = () => {
  return store
}

export { store }
export default createStore
