import Socket from '../services/Socket'
import { WS_URL } from '../helpers/socket'
import { getUserId, socketListener } from './socketHelper'

export default (context, inject) => {
  const { store } = context
  let socket = {}

  const timerId = setInterval(() => {
    init()
  }, 1000)

  function init(impersonateUserId = null) {
    const userId = impersonateUserId || getUserId(store)
    if (userId) {
      socket = new Socket(userId, WS_URL)
      socket.connect()
      socket.on('connect', () => {
        socket.ws.addEventListener('message', (event) =>
          socketListener(event, store)
        )
      })
      clearInterval(timerId)
    }
  }
  socket.init = init
  inject('socket', socket)
}
