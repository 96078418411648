//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconOrdersLargePath',
}
