//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vuex from 'vuex'
import NotificationItem from './NotificationItem'

export default {
  name: 'Notifications',
  components: { NotificationItem },
  computed: {
    ...Vuex.mapGetters({
      notifications: 'ui/notifications',
    }),
  },
  methods: {
    close(index) {
      const { notifications: notificationsList } = this
      const length = notificationsList.length
      if (index === null || typeof index === 'undefined' || length === 0)
        return false
      let notifications = [...notificationsList]
      if (length === 1) {
        notifications = []
      } else {
        notifications.splice(index, 1)
      }
      this.$store.commit('ui/SET_NOTIFICATIONS', notifications)
    },
  },
}
