import {
  FORGOT_PASSWORD_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
  RESET_PASSWORD_HASH_ROUTE_NAME,
} from '~/helpers/route'

export default function ({ $auth, route, redirect }) {
  if (
    $auth?.loggedIn &&
    route?.name &&
    [
      FORGOT_PASSWORD_ROUTE_NAME,
      RESET_PASSWORD_HASH_ROUTE_NAME,
      LOGIN_ROUTE_NAME,
    ].includes(route.name)
  ) {
    redirect({ name: 'index' })
  }
}
