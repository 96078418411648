//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vuex from 'vuex'
import loginImg from '~/ZenOne-StoryBook/components/login/login-img/index.vue'
import Alert from '~/ZenOne-StoryBook/components/alert'
import FullScreenLoader from '~/ZenOne-StoryBook/components/full-screen-loader'

export default {
  name: 'AuthLayout',
  components: {
    loginImg,
    FullScreenLoader,
    Alert,
  },
  mixins: [],
  data: () => ({}),
  computed: {
    ...Vuex.mapGetters({
      alert: 'ui/alert',
    }),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
}
