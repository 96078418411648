import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { getBaseUrl } from '~/config/api'
import { headers } from '~/ZenOne-StoryBook/config/auth'

export const setUserData = (user = {}, store = {}) => {
  if (!isHavingProps(user) || !isHavingProps(store)) return false
  store.commit('user/SET_USER', user)
  setLocalStorage({
    name: 'user',
    data: user,
  })
}

export const getAuthorizedUser = async ($axios = {}) => {
  const user = await $axios.get(
    `${getBaseUrl(process.env.NODE_ENV)}/api/auth/user`,
    {},
    {
      baseURL: null,
      headers,
    }
  )
  return user?.data?.data ?? {}
}

export const setCustomUser = async ($auth = {}, $axios = {}, store = {}) => {
  const user = await getAuthorizedUser($axios)
  $auth.setUser(user)
  setUserData(user, store)
}
