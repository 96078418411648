//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as types from '../../../helpers/ui'
import BtnTextLink from '../../../components/btn-text-link'
import { isNotUndefined } from '../../../helpers/types'

export default {
  name: 'NotificationItem',
  components: { BtnTextLink },
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showAdditionalBtn() {
      return (
        isNotUndefined(this?.item?.additionalBtn?.text) &&
        this.item.additionalBtn.text
      )
    },
  },
  mounted() {
    const {
      item: { timeout },
      hideItem,
    } = this
    if (timeout) {
      setTimeout(hideItem, timeout)
    }
  },
  methods: {
    clickHandler(event) {
      event.stopPropagation()
    },
    getIconColor() {
      return this.item.type === types.SUCCESS ? 'primary' : 'error'
    },
    getIcon() {
      return this.item.type === types.SUCCESS
        ? 'mdi-check-circle-outline'
        : 'mdi-alert'
    },
    async download({ url, fileName }) {
      try {
        const response = await fetch(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        const blob = await response.blob()
        const blobUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        console.error(`File download error: ${e}`)
      }
    },
    async onClose(e) {
      const { hideItem } = this
      const { target } = e
      if (!target) {
        hideItem()
      }
      const { tagName } = target
      if (tagName === 'A') {
        e.preventDefault()
        const { href: url } = target
        const fileName = target.dataset.name
        await this.download({ url, fileName })
        hideItem()
      }
      hideItem()
    },
    hideItem() {
      return this.close(this.index)
    },
  },
}
