import Vuex from 'vuex'
import { filterByProp } from '../helpers/array'
import { SUCCESS, ERROR } from '../helpers/ui'
import { isNotUndefined } from '../helpers/types'
import { ALERT_DEFAULT_TIMEOUT } from '../helpers/ui'
export default {
  data() {
    return {
      scrollSettings: {
        suppressScrollX: true,
        scrollPanel: {
          scrollingX: true,
        },
      },
      scroll: false,
      SUCCESS,
      ERROR,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      notifications: 'ui/notifications',
      isFSLoaderShow: 'ui/showFullScreenLoader',
    }),
  },
  methods: {
    showDialog(alias) {
      const dialogOnOpenFunction =
        this?.$refs?.[alias].onOpen || this?.refList?.[alias].onOpen
      if (isNotUndefined(dialogOnOpenFunction)) dialogOnOpenFunction()
    },
    showAlert({
      type = '',
      content = '',
      actionComponent = null,
      timeout = ALERT_DEFAULT_TIMEOUT,
    }) {
      this.$store.commit('ui/SHOW_ALERT', {
        type,
        content,
        show: true,
        actionComponent,
        timeout,
      })
    },
    hideAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
    showNotification({
      alias,
      type,
      content,
      closeElement = null,
      timeout = null,
      additionalBtn = {
        text: '',
        action: null,
      },
    }) {
      const { notifications } = this
      const notificationsList = [...notifications]
      notificationsList.push({
        alias,
        type,
        content,
        closeElement,
        timeout,
        additionalBtn,
      })
      this.$store.commit('ui/SET_NOTIFICATIONS', notificationsList)
    },
    clearAllNotifications() {
      this.$store.commit('ui/HIDE_NOTIFICATIONS')
    },
    hideNotifications(alias, type = null) {
      const { notifications } = this
      let notificationsList = [...notifications]
      if (!type) {
        notificationsList = filterByProp(
          notificationsList,
          'alias',
          alias,
          false
        )
      } else if (type) {
        const notificationsListFiltered = filterByProp(
          notificationsList,
          'alias',
          alias
        )
        notificationsList = filterByProp(
          notificationsListFiltered,
          'type',
          type,
          false
        )
      }
      this.$store.commit('ui/SET_NOTIFICATIONS', notificationsList)
    },
    showFSLoader() {
      this.$store.commit('ui/SET_SHOW_FULL_SCREEN_LOADER', true)
    },
    hideFSLoader() {
      this.$store.commit('ui/SET_SHOW_FULL_SCREEN_LOADER', false)
    },
    overflowHidden() {
      const html = document.querySelector('html')
      html.style.overflow = 'hidden'
    },
    overflowAuto() {
      const html = document.querySelector('html')
      html.style.overflow = 'auto'
    },
    _onHandleScroll(vertical) {
      this.scroll = vertical.scrollTop > 5
    },
    showSnackbar(text) {
      this.$store.commit('ui/SET_SHOW_SNACKBAR', { text })
    },
  },
}
