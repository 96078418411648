import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'

const isRefresh = (url) => {
  const endpoint = url.slice(url.lastIndexOf('/') + 1)
  return endpoint && endpoint === 'refresh'
}

export default function (context) {
  const { $axios, store } = context
  $axios.interceptors.request.use(
    (config) => {
      const { $auth } = context
      const { url = null } = config
      if (url && isRefresh(url)) {
        const user = store.getters['user/user']
        if (!isHavingProps(user)) return config
        const { refresh_token: refreshToken = null, email = null } = user
        if (!refreshToken || !email) return config
        const accessToken = $auth.strategy.token.get()
        config.headers.Authorization = ''
        config.data = {
          email,
          accessToken,
          refreshToken,
        }
      }
      return config
    },
    (error) => Promise.reject(error)
  )
  $axios.interceptors.response.use(
    (response) => {
      const {
        config: { url = null },
      } = response
      if (url && isRefresh(url)) {
        const {
          data: { AccessToken },
        } = response
        if (AccessToken) {
          const { $auth } = context
          if ($auth && isHavingProps($auth)) {
            $auth.setUserToken(AccessToken)
          }
        }
      }
      return response
    },
    (error) => Promise.reject(error)
  )
}
