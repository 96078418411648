const middleware = {}

middleware['authedRedirects'] = require('../middleware/authedRedirects.js')
middleware['authedRedirects'] = middleware['authedRedirects'].default || middleware['authedRedirects']

middleware['checkPartnerStatus'] = require('../middleware/checkPartnerStatus.js')
middleware['checkPartnerStatus'] = middleware['checkPartnerStatus'].default || middleware['checkPartnerStatus']

middleware['fetchJsonApi'] = require('../middleware/fetchJsonApi.js')
middleware['fetchJsonApi'] = middleware['fetchJsonApi'].default || middleware['fetchJsonApi']

middleware['impersonateUser'] = require('../middleware/impersonateUser.js')
middleware['impersonateUser'] = middleware['impersonateUser'].default || middleware['impersonateUser']

export default middleware
