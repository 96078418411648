//
//
//
//
//
//

export default {
  name: 'IconTasksPath',
}
