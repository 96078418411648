import * as types from './mutation-types'
import { SUCCESS } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  [types.SET_SHOW_FULL_SCREEN_LOADER](state, payload) {
    state.showFullScreenLoader = payload
  },
  [types.SHOW_ALERT](state, payload) {
    state.alert = payload
  },
  [types.HIDE_ALERT](state) {
    state.alert = {
      show: false,
      content: '',
      type: SUCCESS,
    }
  },
  [types.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload
  },
  [types.HIDE_NOTIFICATIONS](state) {
    state.notifications = []
  },
  [types.SET_LEFT_MENU_ITEMS_LIST](state, payload) {
    state.leftMenuItemsList = payload
  },
}
