import { isNotUndefined, isNull, isObject } from '../helpers/types'

export const hasProperty = () => Object.prototype.hasOwnProperty
export const isHavingProps = (object) => Object.keys(object).length > 0
export const isObjectNotUndefinedHasProps = (obj = {}) =>
  !isNull(obj) && isNotUndefined(obj) && isObject(obj) && isHavingProps(obj)

// convert collection(object of objects) to array with id-prop
export const convertCollection = (obj) => {
  if (obj) {
    const keys = Object.keys(obj)
    return keys.map((key) => {
      return { id: key, ...obj[key] }
    })
  }
}

export const setAllValuesToNull = (object = {}) => {
  if (!isObject(object)) return object
  const objectCopy = { ...object }
  const keys = Object.keys(objectCopy)
  for (let i = 0; i < keys.length; i++) {
    objectCopy[keys[i]] = null
  }
  return objectCopy
}
