//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconSettingLargePath',
}
