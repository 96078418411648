import {
  AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
} from '~/helpers/route'
import {
  IMPERSONATE_USER_EMAIL_LS_ALIAS,
  IMPERSONATE_USER_ID_LS_ALIAS,
} from '~/ZenOne-StoryBook/helpers/impersonate'
import {
  getLocalStorage,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { setCustomUser } from '~/helpers/user'

export default async function ({
  route = {},
  $auth = {},
  $axios = {},
  store = {},
  redirect = () => {},
  $socket = {},
}) {
  const routeName = route?.name ?? ''
  if (routeName === AUTH_IMPERSONATE_USER_ID_ROUTE_NAME) {
    const isLoggedIn = $auth?.loggedIn ?? false
    const hasImpersonation =
      getLocalStorage(IMPERSONATE_USER_EMAIL_LS_ALIAS) &&
      getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS)
    if (isLoggedIn && !hasImpersonation) {
      await redirect('/')
      return false
    }
    const impersonateUserId = route?.params?.id ?? null
    if (impersonateUserId) {
      setLocalStorage({
        name: IMPERSONATE_USER_ID_LS_ALIAS,
        data: parseInt(impersonateUserId),
      })
      if (!isLoggedIn) {
        redirect(`/${LOGIN_ROUTE_NAME}`)
        return false
      } else {
        $socket.init(impersonateUserId)
        await setCustomUser($auth, $axios, store)
        redirect('/')
      }
    }
  }
}
