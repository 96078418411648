import { isUndefined } from 'lodash'
import { getLocalStorage } from '../helpers/localStorage'
import { isHavingProps, isObjectNotUndefinedHasProps } from '../helpers/object'
import {
  DISTRIBUTOR_PROCESS,
  DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT,
  isCartItemsDeleted,
  isDistributorLoginResult,
  isDistributorProcess,
  isEmailConfirmed,
  isOrderDeliveryConfirmation,
  isParserMessage,
  isPaymentInvoice,
  isPaymentNotificationMessage,
  isPriceUpdate,
  ORDER_ISSUE,
} from '../helpers/socket'
import { CUSTOMER_ROLE } from '../helpers/roles'

export const getUserId = (store = {}) => {
  let userId = store.state?.user?.userId ?? null
  if (!userId) {
    const user = getLocalStorage('user')
    if (isObjectNotUndefinedHasProps(user)) {
      userId = user?.id ?? null
    }
  }
  return userId
}

function getUser(store = {}) {
  const user = store.getters['user/user']
  if (user && isHavingProps(user)) return user
  return getLocalStorage('user') || {}
}

export const socketListener = (message = {}, store) => {
  const parsed = JSON.parse(message.data)

  if (parsed.data && parsed.data.type && parsed.data.type === 'connection') {
    return false
  }
  const { type = '' } = parsed
  // todo need to refactor
  if (
    isParserMessage(type) ||
    isPaymentNotificationMessage(type) ||
    isCartItemsDeleted(type) ||
    isDistributorProcess(type) ||
    isOrderDeliveryConfirmation(type) ||
    isDistributorLoginResult(type) ||
    isPriceUpdate(type) ||
    isEmailConfirmed(type)
  ) {
    const action = getVuexActionName(getUser(store), type)
    if (!action) return false
    store.dispatch(`socket/${action}`, parsed)
    if (
      (type === DISTRIBUTOR_PROCESS &&
        parsed?.subType === DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT) ||
      isDistributorLoginResult(type)
    ) {
      store.dispatch('distributors/setDistributor', parsed.meta)
    }
  }
  if (type === ORDER_ISSUE || isPaymentInvoice(type)) {
    store.commit('issue/setIssue', parsed.meta)
  }
}

export const getVuexActionName = (user = {}, type = '') => {
  const role =
    // eslint-disable-next-line no-prototype-builtins
    isUndefined(user) || !user.hasOwnProperty('role') ? null : user.role
  if (!role) return null
  if (role === CUSTOMER_ROLE) {
    return isPriceUpdate(type) ? 'updatePrice' : 'addNotification'
  } else {
    return 'addParserMessage'
  }
}
