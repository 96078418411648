//
//
//
//
//
//

export default {
  name: 'GitCurrentReleaseTag',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      tag: process.env.GIT_CURRENT_RELEASE_TAG,
    }
  },
  computed: {
    show() {
      return !!this.tag
    },
  },
}
