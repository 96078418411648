//
//
//
//
//
//

export default {
  name: 'IconCatalogLargePath',
}
