import { isObjectNotUndefinedHasProps } from './object'

export const removeLocalStorageItem = (key) => {
  return localStorage.removeItem(key)
}
export const setLocalStorage = (e) => {
  return localStorage.setItem(e.name, JSON.stringify(e.data))
}
export const getLocalStorage = (alias) => {
  if (alias.length) {
    return JSON.parse(localStorage.getItem(alias))
  } else {
    return false
  }
}

export const clearLocalStorage = () => {
  localStorage.clear()
}

export const removeLocalStorageProp = (alias = '', propName = '') => {
  if (!alias || !propName) return false
  const data = getLocalStorage(alias)
  if (!data || !isObjectNotUndefinedHasProps(data)) return false
  // eslint-disable-next-line no-prototype-builtins
  if (!data.hasOwnProperty(propName)) return false
  delete data[propName]
  setLocalStorage({
    name: alias,
    data,
  })
}

export const getLocalStorageProp = (alias = '', propName = '') => {
  if (!alias || !propName) return null
  const data = getLocalStorage(alias)
  if (!data || !isObjectNotUndefinedHasProps(data)) return null
  return data?.[propName] ?? null
}

export const saveLocalStorageProp = (
  alias = '',
  propName = '',
  payload = null
) => {
  if (!alias || !propName) return false
  const data = getLocalStorage(alias) ?? {}
  setLocalStorage({
    name: alias,
    data: {
      ...data,
      [propName]: payload,
    },
  })
  return true
}
