//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BtnTextLink',
  props: {
    text: {
      type: String,
      default: '',
    },
    stylesClass: {
      type: String,
      default: '',
    },
    isHavingIcon: {
      type: Boolean,
      default: true,
    },
    isHavingIconImage: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    iconMarginRight: {
      type: String,
      default: '3',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getMargin() {
      const { iconMarginRight } = this
      if (this.iconMarginRight) {
        return {
          marginRight: `${iconMarginRight}px`,
        }
      }
      return {}
    },
  },
}
