//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIconBase from '../SvgIconBase'
import IconChatPath from './IconChatPath'
export default {
  name: 'IconChat',
  components: { SvgIconBase, IconChatPath },
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
