export const getBaseUrl = (nodeEnv) => process.env.BASE_URL
export const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
}
export const getJsonApiHeaders = () => {
  return { headers }
}
export const API = {
  ALTERNATIVE_PRODUCT_DISTRIBUTORS: 'alternatives',
  AUTH: 'auth',
  ADD_TO_STRIPE: 'add-to-stripe',
  ATTACH_PAYMENT_METHOD: 'attach-payment-method',
  ADD: 'add',
  ADDRESS: 'address',
  ADDRESS_DISTRIBUTORS: 'address-distributors',
  ATTRIBUTES: 'attributes',
  ATTRIBUTE_VALUES: 'attribute-values',
  AUTH_RECOVERY: 'auth/recovery',
  BLOCKED_DISTRIBUTORS: 'blocked-distributors',
  BLOCKED_BY_MANUFACTURERS: 'blocked-by-manufacturers',
  GET_PRACTICE_BY_LICENSE: 'get-practice-by-license',
  GENERATE_PASSWORD: 'generate-password',
  CREATE_CONSUMER: 'create-consumer',
  CREATE_SETUP_INTENT: 'create-setup-intent',
  CONFIRM: 'confirm',
  RESEND_EMAIL_CONFIRMATION: 'resend-email-confirmation',
  CART: 'cart',
  CART_ITEM: 'cart-item',
  CART_ITEMS: 'cart-items',
  CATEGORIES: 'categories',
  CATEGORY: 'category',
  CATEGORY_MODIFIERS: 'category-modifiers',
  CHECK_LICENSE: 'check-license',
  CHECKOUT: 'checkout',
  COUNTRIES: 'countries',
  DISTRIBUTOR: 'distributor',
  DISTRIBUTORS: 'distributors',
  DISTRIBUTORS_LIGHTS: 'distributors-lights',
  DISTRIBUTOR_USER_SETTINGS: 'distributor-user-settings',
  DISTRIBUTOR_SETTINGS: 'distributor-settings',
  FILES: 'files',
  FILE: 'file',
  FOLDERS: 'work-spaces', // todo change to new api
  FOLDER_ITEMS: 'work-space-items', // todo change to new api
  IMAGES: 'images',
  INVITE: 'invite',
  IS_PRIVATE_LABEL_DISTRIBUTOR: 'is-private-label-distributor',
  IS_SELL_DIRECT_MANUFACTURER: 'is-sell-direct-manufacturer',
  LATEST_PAYMENT: 'latest-payment',
  LOCALES: 'locales',
  LOGO: 'logo',
  MANAGING_USER: 'managing-user',
  MANUFACTURERS: 'manufacturers',
  MANUFACTURERS_LIGHTS: 'manufacturers-lights',
  MANUFACTURER: 'manufacturer',
  MODIFIERS: 'modifiers',
  MODIFIER: 'modifier',
  MODIFIER_VALUES: 'modifier-values',
  ORDERS: 'orders',
  ORDER_CLAIMS: 'order-claims',
  ORDER_INVOICES: 'order-invoices',
  ORDER_INVOICE: 'order-invoice',
  ORDER_ITEMS: 'order-items',
  ORDER_ISSUES: 'order-issues',
  ORDER_HISTORIES: 'order-histories',
  ORDER_PAYMENTS: 'order-payments',
  ORDER_PINS: 'order-pins',
  ORDER_RETURNS: 'order-returns',
  ORDER_SHIPPINGS: 'order-shippings',
  ORDER_SHIPPING: 'order-shipping',
  PAYMENTS: 'payments',
  PLACE_ORDER: 'place-order',
  PARSERS: 'parsers',
  PRACTICES: 'practices',
  PRACTICE: 'practice',
  RELATION_WITH_PRACTICE_AND_USER: 'relation-with-practice-and-user',
  PRACTICE_DISTRIBUTORS: 'practice-distributors',
  PRACTICE_LOCATIONS: 'practice-locations',
  PRACTICE_LOCATION: 'practice-location',
  PRACTICE_LOCATION_GROUPS: 'practice-location-groups',
  PRACTICE_LOCATION_DISTRIBUTORS: 'practice-location-distributors',
  PRACTICE_LOCATION_CONTACT_NOTES: 'practice-location-contact-notes',
  PRIMARY_MODIFIERS: 'primary-modifiers',
  PRIVATE_LABEL_MANUFACTURER: 'private-label-manufacturer',
  PRODUCT_MODEL: 'product-model',
  PRODUCT_COUNT: 'product-count',
  PRODUCT_MODEL_MODIFIERS: 'product-model-modifiers',
  PRODUCT_MODELS: 'product-models',
  PRODUCT_PRICES: 'product-prices',
  PRODUCT_WITH_TRASHED: 'product-with-trashed',
  PRODUCTS_WITH_TRASHED: 'products-with-trashed',
  PRODUCT_DISTRIBUTOR: 'product-distributor',
  PRODUCT_DISTRIBUTOR_PRICES: 'product-distributor-prices',
  PRODUCT_DISTRIBUTORS: 'product-distributors',
  PRODUCT_DISTRIBUTORS_WITH_TRASHED: 'product-distributors-with-trashed',
  PRODUCTS: 'products',
  PRODUCT: 'product',
  REGISTER: 'register',
  RESEND_PASSWORD: 'resend-password',
  RESET_PASSWORD: 'reset-password',
  CHECK_RESET_PASSWORD_TOKEN: 'check-reset-password-token',
  SPECIALITIES: 'specialities',
  SEARCH: 'search',
  SECONDARY_MODIFIERS: 'secondary-modifiers',
  SELL_DIRECT_DISTRIBUTOR: 'sell-direct-distributor',
  SUGGEST: 'suggest',
  SDS_FILE: 'sds-file',
  SHIPPING_COMPANIES: 'shipping-companies',
  SUBSCRIPTION_PAYMENTS: 'subscription-payments',
  SUBSCRIPTION_PLANS: 'subscription-plans',
  SUBSCRIPTION_RECHARGE: 'recharge',
  SYNONYMS: 'synonyms',
  THUMB: 'thumb',
  THUMBS: 'thumbs',
  USER: 'user',
  USERS: 'users',
  WAREHOUSES: 'warehouses',
  WORK_SPACES: 'work-spaces',
  WORK_SPACE_ITEMS: 'work-space-items',
  ZIPS: 'zips',
  ZEN_REP_TASKS: 'zen-rep-tasks',
}
