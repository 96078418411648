import { store } from '../../index'
import * as types from './mutation-types'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
import { fetchManufacturer } from '~/ZenOne-StoryBook/helpers/manufacturer'
import { setMenuItemsStandard, setMenuItemsWithOrders } from '~/menus/left-menu'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'

export default {
  async SetLeftMenuItemsList({ commit }, $jsonApi) {
    let list = []
    const isManufacturer = store.getters['user/isManufacturer']
    const manufacturerId = store.getters['user/manufacturerId']
    let setMenuListFunction = setMenuItemsWithOrders
    if (isManufacturer) {
      const manufacturer = await fetchManufacturer(
        manufacturerId,
        false,
        $jsonApi,
        true
      )
      if (manufacturer && isHavingProps(manufacturer)) {
        let sellDirect = manufacturer?.sellDirect
        sellDirect = isNotUndefined(sellDirect) ? sellDirect : false
        if (!sellDirect) setMenuListFunction = setMenuItemsStandard
      }
    }
    if (setMenuListFunction !== null) {
      list = setMenuListFunction()
    }
    commit(`ui/${types.SET_LEFT_MENU_ITEMS_LIST}`, list, { root: true })
  },
}
