import Kitsu from 'kitsu'

export default function (ctx, inject) {
  const kitsuOptions = {
    baseURL: 'https://api.testing.zenone.com/api/v1',

    camelCaseTypes: false,

    pluralize: false,
  }

  const jsonApi = new Kitsu(kitsuOptions)

  ctx.$jsonApi = jsonApi
  inject('jsonApi', jsonApi)
}
