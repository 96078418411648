//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIconBase from '../SvgIconBase'
import IconUserPath from './IconUserPath'
export default {
  name: 'IconUser',
  components: { SvgIconBase, IconUserPath },
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
