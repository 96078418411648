//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconUser from '../SvgIcons/IconUser'
import IconLogo from '../SvgIcons/IconLogo'
import GitCurrentReleaseTag from '../../components/GitCurrentReleaseTag'
import LeftMenuItem from './LeftMenuItem'
import iconLogOut from './icon-log-out.svg'
import NewPasswordModal from './NewPasswordModal'

export default {
  name: 'LeftMenu',
  components: {
    GitCurrentReleaseTag,
    IconLogo,
    IconUser,
    LeftMenuItem,
    NewPasswordModal,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    largeIcons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconLogOut,
      openNewPasswordModal: false,
    }
  },
  methods: {
    onResetPassword() {
      this.openNewPasswordModal = true
    },

    onCloseNewPasswordModal() {
      this.openNewPasswordModal = false
    },

    logOut() {
      this.$emit('logOut')
    },

    editProfile() {
      this.$emit('editProfile')
    },
  },
}
