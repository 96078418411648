//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIconBase from '../SvgIconBase'
import IconLogoPath from './IconLogoPath'
export default {
  name: 'IconLogo',
  components: { SvgIconBase, IconLogoPath },
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
