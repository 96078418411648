import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'

export default function ({ app, store, redirect }) {
  app.router.beforeEach((to, from, next) => {
    const { name: toName } = to
    if (toName === 'index') {
      let partnerIsActive = false
      const manufacturerStatus = store?.state?.user?.user?.manufacturer?.status
      const distributorStatus = store?.state?.user?.user?.distributor?.status
      if (
        isNotUndefined(manufacturerStatus) &&
        ['active', 'in_review'].includes(manufacturerStatus)
      ) {
        partnerIsActive = true
      } else if (
        isNotUndefined(distributorStatus) &&
        ['active', 'in_review'].includes(distributorStatus)
      ) {
        partnerIsActive = true
      }
      return partnerIsActive ? redirect('/catalog') : redirect('/settings')
    }
    next()
  })
}
