import actions from './actions'
import mutations from './mutations'
import {
  DISTRIBUTOR_ROLE,
  MANUFACTURER_ROLE,
} from '~/ZenOne-StoryBook/helpers/roles'

const user = {
  state: {
    user: localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : {},
  },
  getters: {
    user: (state) => state.user,
    name: (state) => state.user.name,
    userId: (state) => state.user.id,
    manufacturer: (state) => state.user.manufacturer,
    distributor: (state) => state.user.distributor,
    role: (state) => state.user.role,
    isManufacturer: (state) => {
      const {
        user: { role },
      } = state
      return role === MANUFACTURER_ROLE
    },
    manufacturerId: (state) =>
      state.user && state.user.manufacturer && state.user.manufacturer.id
        ? state.user.manufacturer.id
        : null,
    isDistributor: (state) => {
      const {
        user: { role },
      } = state
      return role === DISTRIBUTOR_ROLE
    },
    distributorId: (state) =>
      state.user && state.user.distributor && state.user.distributor.id
        ? state.user.distributor.id
        : null,
    partnerName: (state, getters) => {
      if (getters.isDistributor) {
        return getters.distributor?.name ?? 'Distributor'
      } else if (getters.isManufacturer) {
        return getters.manufacturer?.name ?? 'Manufacturer'
      }
      return 'Partner'
    },
  },
  mutations,
  actions,
  namespaced: true,
}

export default user
