//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as types from '../../helpers/ui'
import { ALERT_DEFAULT_TIMEOUT } from '../../helpers/ui'

export default {
  name: 'Alert',
  props: {
    close: { type: Function },
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: types.SUCCESS,
    },
    timeout: {
      type: Number,
      default: ALERT_DEFAULT_TIMEOUT,
    },
  },
  data() {
    return {
      types,
    }
  },
  mounted() {
    const { onClose } = this
    setTimeout(onClose, this.timeout)
  },
  methods: {
    onClose() {
      this.close()
    },
    getColor() {
      const { type } = this
      if (type === types.SUCCESS) {
        return 'green01'
      } else if (type === types.ERROR) {
        return 'error'
      }
    },
  },
}
