//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewPasswordUserAuthorization from '../../login/NewPasswordUserAuthorization'
import loginImg from '../../login/login-img'
import { getBaseUrl } from '../../../../config/api'
import ui from '~/ZenOne-StoryBook/mixins/ui'

export default {
  components: {
    NewPasswordUserAuthorization,
    loginImg,
  },
  mixins: [ui],
  layout: 'auth',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },

    async onNewPasswordUserAuthorization({ password }) {
      const { showFSLoader, hideFSLoader, showAlert, SUCCESS, ERROR } = this
      let alert = {
        type: SUCCESS,
        content: 'Password was successfully changed.',
      }
      try {
        showFSLoader()
        await this.$axios.post(
          `${getBaseUrl(process.env.NODE_ENV)}/api/auth/change-password`,
          {
            password,
            repassword: password,
          },
          {
            baseURL: null,
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          }
        )

        this.onClose()
      } catch (e) {
        if (e.response.status === 404) {
          alert = {
            type: ERROR,
            content: `Password Changes - Error, ${e}`,
          }
        }
        console.error('onNewPasswordUserAuthorization', e)
      } finally {
        hideFSLoader()
        showAlert(alert)
      }
    },
  },
}
