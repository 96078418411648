//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIconBase from '../SvgIconBase'
import { DEFAULT } from '../../../helpers/icons'
import IconConsumersPath from './IconConsumersPath'
export default {
  name: 'Consumers',
  components: { SvgIconBase, IconConsumersPath },
  props: {
    size: {
      type: String,
      default: DEFAULT,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      DEFAULT,
    }
  },
  computed: {
    width() {
      return this.size === DEFAULT ? 23 : 28
    },
    height() {
      return this.size === DEFAULT ? 23 : 28
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`
    },
  },
}
