//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconSettingsPath',
}
