//
//
//
//
//
//

export default {
  name: 'IconCatalogPath',
}
