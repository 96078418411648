export const ORDERS_ITEM_ALIAS = 'orders'

export const items = [
  /*  {
    id: 1,
    title: 'Dashboard',
    route: '/',
    icon: 'DashboardLarge',
    alias: 'dashboard',
  }, */
  {
    id: 1,
    title: 'Catalog',
    route: '/catalog',
    icon: 'CatalogLarge',
    alias: 'catalog',
  },
  {
    id: 2,
    title: 'Partners',
    route: '/partners',
    icon: 'PartnersLarge',
    alias: 'partners',
  },
  {
    id: 3,
    title: 'Orders',
    route: '/orders',
    icon: 'OrdersLarge',
    alias: ORDERS_ITEM_ALIAS,
  },
  {
    id: 4,
    title: 'Settings',
    route: '/settings',
    icon: 'SettingsLarge',
    alias: 'settings',
  },
]

export const setMenuItemsStandard = () => {
  return items.filter((item) => item.alias !== ORDERS_ITEM_ALIAS)
}

export const setMenuItemsWithOrders = () => items
