import { api } from '../../config/api'

export const status = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  ON_HOLD: 'on_hold',
}

export const statusList = [
  { id: 1, text: 'Active', value: status.ACTIVE },
  { id: 2, text: 'Draft', value: status.DRAFT },
  { id: 3, text: 'In Review', value: status.IN_REVIEW },
  { id: 4, text: 'On Hold', value: status.ON_HOLD },
]

export const statusListManufacturerList = [
  { id: 1, text: 'Active', value: status.ACTIVE },
  { id: 2, text: 'In Review', value: status.IN_REVIEW },
  { id: 3, text: 'On Hold', value: status.ON_HOLD },
]

// manufacturers-list
export const sortTypes = {
  BY_MANUFACTURER_NAME: 'name',
  // BY_DISTRIBUTORS_COUNT: api.BLOCKED_DISTRIBUTORS,
  BY_PRODUCTS_COUNT: api.PRODUCTS,
}

export const fetchManufacturer = async (
  id,
  sellDirect = false,
  $jsonApi = null,
  withoutInclude = false
) => {
  let params = {}
  if (!id || !$jsonApi) return null
  if (!withoutInclude) {
    let include = `${api.LOGO},${api.LOGO}.${api.THUMB}`
    if (sellDirect) {
      include += `,${api.SELL_DIRECT_DISTRIBUTOR},${api.SELL_DIRECT_DISTRIBUTOR}.${api.LOGO},${api.SELL_DIRECT_DISTRIBUTOR}.${api.LOGO}.${api.THUMB}`
    }
    params = {
      include,
    }
  }

  const request = await $jsonApi.get(`${api.MANUFACTURERS}/${id}`, params)
  const { data: manufacturer } = request
  return manufacturer
}
