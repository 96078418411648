import * as types from './mutation-types'

export default {
  [types.ADD_PARSER_MESSAGE](state, payload) {
    const { type: parserType } = payload
    state.parsers[parserType] = [...state.parsers[parserType], payload]
  },
  [types.CLEAR_MESSAGES_BY_TYPE](state, payload) {
    const { section, type: parserType } = payload
    if (section && parserType) state[section][parserType] = []
    else console.error('Please pass socket messages section and parser type')
  },
}
