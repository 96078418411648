//
//
//
//
//
//
//
//
//
//

import Vuex from 'vuex'
export default {
  name: 'FullScreenLoader',
  computed: {
    ...Vuex.mapGetters({
      show: 'ui/showFullScreenLoader',
    }),
  },
}
