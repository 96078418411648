//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Notifications from '~/ZenOne-StoryBook/components/Notifications'
import Alert from '~/ZenOne-StoryBook/components/alert'
import LeftMenuVendor from '~/ZenOne-StoryBook/components/LeftMenuVendor'
import FullScreenLoader from '~/ZenOne-StoryBook/components/full-screen-loader'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { IMPERSONATE_USER_ID_LS_ALIAS } from '~/ZenOne-StoryBook/helpers/impersonate'
import NotificationAuthorizationAdminPortal from '~/components/NotificationAuthorizationAdminPortal'

export default {
  name: 'ListLayout',
  components: {
    NotificationAuthorizationAdminPortal,
    Alert,
    LeftMenuVendor,
    Notifications,
    FullScreenLoader,
  },
  computed: {
    ...mapGetters({
      alert: 'ui/alert',
      leftMenuItemsList: 'ui/leftMenuItemsList',
    }),
    adminIsAuthorized() {
      return getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS) ?? false
    },
  },
  async mounted() {
    await this.$store.dispatch('ui/SetLeftMenuItemsList', this.$jsonApi)
  },
  methods: {
    async logOut() {
      await this.$auth.logout('refresh')
      await this.$router.replace({ path: '/login' })
      localStorage.clear()
      this.$router.go(null)
    },
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
}
