import { convertCollection } from '../helpers/object'
import { isArrayWithLength, isNotUndefined, isObject } from '../helpers/types'

export const getPropArrayFromArrayOfObjects = (array, prop) => {
  return array.reduce((acc, cur) => {
    acc.push(cur[prop])
    return acc
  }, [])
}
export const getIdsArray = (array) => {
  return getPropArrayFromArrayOfObjects(array, 'id')
}

// get sorted array by Id from Object of Objects with id-key //
export const getSortedArray = (list, sortOrder) => {
  const array = convertCollection(list)

  const itemPositions = {}
  for (const [index, id] of sortOrder.entries()) {
    itemPositions[id] = index
  }
  array.sort((a, b) => itemPositions[a.id] - itemPositions[b.id])
  return array
}

export const sortByObjectProp = (arrayOfObjects, prop) => {
  arrayOfObjects.sort((a, b) => (a[prop] > b[prop] && 1) || -1)
  return arrayOfObjects
}

export const filterByProp = (list, prop, value, equals = true) => {
  return list.filter((item) => {
    return equals ? item[prop] === value : item[prop] !== value
  })
}

export const findObjectByProp = (list, prop, value) => {
  const index = list.findIndex((item) => item[prop] === value)
  if (index !== -1) return list[index]
  return null
}

export const isArray = (value) => {
  return isNotUndefined(value) && value && Array.isArray(value)
}

export const isHavingLength = (value) => {
  return value.length > 0
}

export const isArrayAndHaveLength = (value) => {
  return isArray(value) && isHavingLength(value)
}

export const arrayDiff = (a, b) => {
  return [
    ...a.filter((x) => !b.includes(x)),
    ...b.filter((x) => !a.includes(x)),
  ]
}

export const filterArrayEmptyStrings = (array = []) => {
  if (!isArrayAndHaveLength(array)) return []
  return array.filter((item) => {
    return item.length > 0
  })
}

export const removeArrayStringDuplicates = (array = []) => {
  if (!isArrayAndHaveLength(array)) return []
  return [...new Set(array)]
}

export const sortArrayByIncludeInAnotherArray = (
  originArray = [],
  compareArray = []
) =>
  [...originArray].sort(
    (a, b) => compareArray.indexOf(a) - compareArray.indexOf(b)
  )

export const findIndexByPropNameAndValue = (
  array = [],
  propName = '',
  propValue
) => {
  if (!isArrayWithLength(array)) return -1
  return array.findIndex((el) => el?.[propName] === propValue)
}

export const arrayToTree = (
  array = [],
  id = null,
  parentLink = 'parent_id',
  sortFunction = null
) =>
  array
    .filter((item) => item?.[parentLink] === id)
    .map((item) => {
      const sortedArray = !sortFunction
        ? [...array]
        : [...array].sort(sortFunction)
      return {
        ...item,
        children: arrayToTree(sortedArray, item?.id),
      }
    })

export const findAllDescendantsFlattenedById = (data = [], id = null) => {
  const result = []

  function findAndFlattenChildren(node) {
    if (node.id === id && node.children) {
      flattenChildren(node.children)
    } else if (node.children) {
      node.children.forEach(findAndFlattenChildren)
    }
  }

  function flattenChildren(children) {
    children.forEach((child) => {
      result.push(child)
      if (child.children) {
        flattenChildren(child.children)
      }
    })
  }

  if (Array.isArray(data)) {
    data.forEach(findAndFlattenChildren)
  } else {
    findAndFlattenChildren(data)
  }

  return result
}

export const getArrayCopyFromObjectProp = (object = {}, prop = '') =>
  isObject(object) && object?.[prop] && isArrayWithLength(object[prop])
    ? [...object[prop]]
    : []

export const concatAndRemoveDuplicates = (array1, array2) => {
  const combinedArray = [...array1, ...array2]
  return [...new Set(combinedArray)]
}
