//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SvgIconBase',
  props: {
    name: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
    viewBox: {
      type: [String],
      default: '0 0 17 18',
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    title: {
      type: String,
      default: 'icon',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      svgWrapperStyle: {
        width: `${this.width}px`,
        height: `${this.height}px`,
      },
    }
  },
}
