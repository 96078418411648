export const WS_URL = process.env.SOCKET_URL + '/echo'

export const PARSERS_SECTION = 'parsers'
export const PARSER_PRODUCTS_MANUFACTURER = 'parserProductsManufacturer'
export const PARSER_PRODUCTS_DISTRIBUTOR = 'parserProductsDistributor'
export const PARSER_PRICES_DISTRIBUTOR = 'parserPricesDistributor'
export const PARSER_CLIENT_PRICES_DISTRIBUTOR = 'parserClientPricesDistributor'
export const PARSER_STOCK_LEVELS_PRODUCT_DISTRIBUTOR =
  'parserStockLevelsProductDistributor'
export const PARSER_FREE_GOODS_MANUFACTURER = 'parserFreeGoodsManufacturer'
export const PARSER_FREE_GOODS_DISTRIBUTOR = 'parserFreeGoodsDistributor'
export const PARSERS_LIST = [
  PARSER_PRODUCTS_MANUFACTURER,
  PARSER_PRODUCTS_DISTRIBUTOR,
  PARSER_PRICES_DISTRIBUTOR,
  PARSER_CLIENT_PRICES_DISTRIBUTOR,
  PARSER_STOCK_LEVELS_PRODUCT_DISTRIBUTOR,
  PARSER_FREE_GOODS_MANUFACTURER,
  PARSER_FREE_GOODS_DISTRIBUTOR,
]

export const NOTIFICATION_PAYMENT_SUBSCRIPTION = 'subscriptionPayment'
export const NOTIFICATION_PAYMENT_INVOICE = 'invoicePayment'
export const NOTIFICATION_PAYMENT_LIST = [
  NOTIFICATION_PAYMENT_SUBSCRIPTION,
  NOTIFICATION_PAYMENT_INVOICE,
]

export const PRICE_UPDATE = 'priceUpdate'

export const EMAIL_CONFIRMED = 'emailConfirmed'

export const DELETE_ITEMS_FROM_CART = 'deleteItemsFromCart'
export const DISTRIBUTOR_PROCESS = 'distributorProcess'
export const ORDER_DELIVERY_CONFIRMATION = 'orderDeliveryConfirmation'
export const DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT = 'loginResult'
export const DISTRIBUTOR_LOGIN_RESULT = 'distributorLoginResult' // after first connect we use only that notification type
export const isParserMessage = (type) => {
  return PARSERS_LIST.includes(type)
}
export const isPaymentNotificationMessage = (type) => {
  return NOTIFICATION_PAYMENT_LIST.includes(type)
}
export const isCartItemsDeleted = (type) => type === DELETE_ITEMS_FROM_CART
export const isDistributorProcess = (type) => type === DISTRIBUTOR_PROCESS
export const isDistributorLoginResult = (type) =>
  type === DISTRIBUTOR_LOGIN_RESULT
export const isOrderDeliveryConfirmation = (type) =>
  type === ORDER_DELIVERY_CONFIRMATION
export const isPaymentInvoice = (type) => type === NOTIFICATION_PAYMENT_INVOICE
export const ORDER_ISSUE = 'orderIssue'

export const isPriceUpdate = (type) => type === PRICE_UPDATE
export const isEmailConfirmed = (type) => type === EMAIL_CONFIRMED
