import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  IMPERSONATE_USER_EMAIL_LS_ALIAS,
  IMPERSONATE_USER_ID_LS_ALIAS,
} from '~/ZenOne-StoryBook/helpers/impersonate'

const isRefresh = (url) => {
  const endpoint = url.slice(url.lastIndexOf('/') + 1)
  return endpoint && endpoint === 'refresh'
}

export default function (context) {
  const { $axios, store, $jsonApi, $sentry } = context
  $axios.interceptors.request.use(
    (config) => {
      const impersonateUserId = getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS)
      if (impersonateUserId) {
        config.headers['x-Impersonate-UserId'] = impersonateUserId
      }
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (!expired) return config
      const { url = null } = config
      if (url && isRefresh(url)) {
        let user = store.getters['user/user']
        if (!user || !isHavingProps(user)) {
          user = getLocalStorage('user')
          if (!user || !isHavingProps(user)) return config
        }
        const email = impersonateUserId
          ? getLocalStorage(IMPERSONATE_USER_EMAIL_LS_ALIAS)
          : user?.email
        const { refreshToken = null } = user
        if (!email || !refreshToken) return config
        const accessToken = $auth.strategy.token.get()
        config.headers.Authorization = ''
        config.data = {
          email,
          accessToken,
          refreshToken,
        }
      }
      return config
    },
    (error) => {
      $sentry.captureException(error)
      return Promise.reject(error)
    }
  )
  $axios.interceptors.response.use(
    (response) => {
      const {
        config: { url = null },
      } = response
      if (url && isRefresh(url)) {
        const {
          data: { AccessToken },
        } = response
        if (AccessToken) {
          const { $auth } = context
          if ($auth && isHavingProps($auth)) {
            $auth.setUserToken(AccessToken)
            $jsonApi.headers = {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              Authorization: $auth.strategy.token.get(),
            }
          }
        }
      }
      return response
    },
    (error) => Promise.reject(error)
  )

  $jsonApi.interceptors.request.use(
    async (config) => {
      const impersonateUserId = getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS)
      if (impersonateUserId) {
        config.headers['x-Impersonate-UserId'] = impersonateUserId
      }
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (expired) {
        await $auth.refreshTokens()
        config.headers = {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          Authorization: $auth.strategy.token.get(),
        }
        return config
      } else {
        return config
      }
    },
    (error) => Promise.reject(error)
  )
  $jsonApi.interceptors.response.use(
    async (response) => {
      const { status } = response
      const { $auth } = context
      if (status === 401) {
        await $auth.refreshTokens()
      }
      return response
    },
    (error) => {
      $sentry.captureException(error)
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (expired) {
        $auth.refreshTokens()
      }
      return Promise.reject(error)
    }
  )
}
