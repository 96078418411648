//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { clearLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default {
  name: 'NotificationAuthorizationAdminPortal',
  computed: {
    ...mapGetters({
      partnerName: 'user/partnerName',
    }),
    text() {
      return `You are acting on behalf of ${this.partnerName}`
    },
  },
  methods: {
    async onClose() {
      clearLocalStorage()
      await this.$auth.logout('refresh')
      window.close()
    },
  },
}
