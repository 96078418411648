//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { IMPERSONATE_USER_ID_LS_ALIAS } from '~/ZenOne-StoryBook/helpers/impersonate'
import NotificationAuthorizationAdminPortal from '~/components/NotificationAuthorizationAdminPortal'

export default {
  components: { NotificationAuthorizationAdminPortal },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
  computed: {
    adminIsAuthorized() {
      return getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS) ?? false
    },
  },
}
