import actions from './actions'
import mutations from './mutations'

const socket = {
  state: {
    parsers: {
      parserProductsManufacturer: [],
      parserProductsDistributor: [],
      parserPricesDistributor: [],
      parserClientPricesDistributor: [],
      parserStockLevelsProductDistributor: [],
      parserFreeGoodsManufacturer: [],
      parserFreeGoodsDistributor: [],
    },
    chat: {},
  },
  getters: {
    parserProductsManufacturer: (state) =>
      state.parsers.parserProductsManufacturer,
    parserProductsDistributor: (state) =>
      state.parsers.parserProductsDistributor,
    parserPricesDistributor: (state) => state.parsers.parserPricesDistributor,
    parserClientPricesDistributor: (state) =>
      state.parsers.parserClientPricesDistributor,
    parserStockLevelsProductDistributor: (state) =>
      state.parsers.parserStockLevelsProductDistributor,
    parserFreeGoodsManufacturer: (state) =>
      state.parsers.parserFreeGoodsManufacturer,
    parserFreeGoodsDistributor: (state) =>
      state.parsers.parserFreeGoodsDistributor,
  },
  mutations,
  actions,
  namespaced: true,
}

export default socket
