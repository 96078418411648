//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import arrow from '../img/arrow.svg'
import iconUser from '../img/icon-user.svg'
import iconPass from '../img/icon-pass.svg'

export default {
  name: 'NewPasswordUserAuthorization',
  data() {
    return {
      arrow,
      iconUser,
      iconPass,
      password: '',
      repassword: '',
      show: false,
      show2: false,
      passwordRules: [
        (value) => !!value || 'Please type password.',
        (value) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z!@#%^&*?|`±№()_\\\\/-=+$.,\\{\\}\\[\]\\'\\"><:;~\d]{8,}$/.test(
            value
          ) ||
          `Password is not valid. It should contain: Minimum eight characters, at least one uppercase letter, one lowercase letter and one number`,
      ],
    }
  },
  computed: {
    hash() {
      return this.$route.query.hash
    },
  },
  methods: {
    onNewPassword() {
      try {
        const { password, hash } = this
        if (this.$refs.form.validate()) {
          const data = {
            password,
            hash,
          }
          this.$emit('onNewPassword', data)
        }
      } catch (error) {
        console.log('onLogin error', error)
      }
    },
  },
}
