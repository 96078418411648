import { EventEmitter } from 'events'

export default class Socket extends EventEmitter {
  ws = {}

  constructor(userId, url) {
    super()
    this.userId = userId
    this.url = url
  }

  sendMessage(body) {
    this.ws.send(body)
  }

  connect() {
    this.ws = new WebSocket(this.url)
    this.ws.addEventListener('open', () => this.onOpen())
    this.ws.addEventListener('close', () => this.onClose())
    this.ws.addEventListener('error', () => this.onError())
  }

  onOpen() {
    this.sendMessage(this.userId)
    this.emit('connect')
  }

  onError() {
    this.ws.close()
  }

  onClose() {
    setTimeout(() => {
      this.connect()
    }, 2000)
  }
}
//
